@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    html {
        @apply font-montserrat;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@import './base/form.scss';
@import './base/base.scss';
@import './base/table.scss';


@media only screen and (min-width: 888px) {
    .canvas-height {
        height: 80vh
    }
}

@media only screen and (max-width: 888px) {
    .canvas-height {
        height: 65vh !important
    }
}

@media only screen and (max-width: 488px) {
    .canvas-height {
        height: 55vh !important
    }
}

.react-flow__attribution {
    display: none;
}

.react-flow__handle,
.delete-button {
    opacity: 0;
}

.react-flow__node:hover .react-flow__handle,
.react-flow__node:hover .delete-button {
    opacity: 1;
}

.hide-pointers:hover .react-flow__handle,
.hide-pointers:hover .delete-button {
    opacity: 0;
}

.touchdevice-flow .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
}

/* ipads.tablets */
@media (min-width: 481px) and (max-width: 768px) {
    .touchdevice-flow .react-flow__handle.target {
        width: 15px;
        height: 15px;
        background-color: red;
    }

    .touchdevice-flow .react-flow__handle.source {
        width: 15px;
        height: 15px;
        background-color: darkcyan;
    }
}

// Mobile Devices
@media (min-width:320px)and (max-width:480px) {
    .touchdevice-flow .react-flow__handle.target {
        width: 20px;
        height: 20px;
        background-color: red;
    }

    .touchdevice-flow .react-flow__handle.source {
        width: 20px;
        height: 20px;
        background-color: black;
    }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: #dadada;
}

body::-webkit-scrollbar-thumb {
    background: #55a8fb;
    border-radius: 200px;
}

// cj select dropdown
.css-13cymwt-control {
    min-width: 150px;
}

.line-clamp3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// for sbp arrows
#line1 {
    position: absolute;
    border-top: 2px solid black;
}

#line2 {
    position: absolute;
    border-top: 2px solid black;
}

#line3 {
    position: absolute;
    border-top: 2px solid black;
}

@media only screen and (min-width: 841px) {
    .health-graph {
        height: 400px;
        width: 650px;
    }
}

@media only screen and (max-width: 840px) {
    .health-graph {
        height: 400px;
        width: 500px;
    }
}

@media only screen and (max-width: 590px) {
    .health-graph {
        height: 400px;
        width: 350px;
    }
}

.ck-editor__editable {
    height: 70vh;
}

//for sop category accordion
.sop-accordion::before {
    content: inherit !important;
}

code {
    color: #0016DB;
    font-style: normal;
    font-weight: 600;
}

.ck-content{
    font-weight: 400;
    letter-spacing: normal;
    line-height: 180%;
    font-size: small;
}