.tcx-input-label {
    @apply block text-body mb-2 text-tertiary-dark font-montserrat;
}

.tcx-input {
    @apply leading-6 block w-full rounded-md bg-neutral-medium border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors duration-300;
}

.tcx-input-editable {
    @apply leading-6 block w-full rounded-md bg-white border-transparent focus:border-gray-500 border-gray-300 focus:ring-0 transition-colors duration-300;
}

.tcx-textarea {
    @apply block w-full rounded-md bg-neutral-medium border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors duration-300;
}

.tcx-input-select {
    @apply block w-full rounded-md bg-neutral-medium border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors duration-300;
}

.input-error {
    @apply p-1 text-sm text-red-700
}

::placeholder {
    @apply text-body
}

.custom-tcx-input-select>.css-t3ipsp-control {
    border: 1px solid rgb(204, 204, 204) !important;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

//for local
.css-qbdosj-Input {
    border: none !important;
}

.css-qbdosj-Input>input {
    outline: 0 !important;
    box-shadow: none !important;
    opacity: 0.8 !important;
    cursor: pointer !important;
}

.css-166bipr-Input>input {
    outline: 0 !important;
    box-shadow: none !important;
    opacity: 0.8 !important;
    cursor: pointer !important;
}

// for live

.css-19bb58m {
    border: none !important;
}

.css-19bb58m>input {
    outline: 0 !important;
    box-shadow: none !important;
    opacity: 0.8 !important;
    cursor: pointer;
}

.css-1cfo1cf>input {
    outline: 0 !important;
    box-shadow: none !important;
    opacity: 0.8 !important;
    cursor: pointer;
}