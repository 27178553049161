// Buttons styling
.btn-primary {
   @apply hover:bg-primary-dark inline-flex items-center justify-center px-4 py-2 text-title tracking-wide text-white transition duration-200 bg-primary rounded whitespace-nowrap leading-6 border-primary disabled:opacity-50 disabled:cursor-not-allowed;
   border-width: 1px;
}

.btn-primary-sm {
   @apply hover:bg-primary-dark inline-flex items-center justify-center px-4 py-2 text-title tracking-wide text-white transition duration-200 bg-primary rounded whitespace-nowrap leading-4 border-primary disabled:opacity-50 disabled:cursor-not-allowed;
   border-width: 1px;
}

.btn-transparent {
   @apply hover:bg-primary-light border border-primary inline-flex items-center justify-center px-2 py-2.5 text-title tracking-wide text-primary transition duration-200 rounded whitespace-nowrap leading-6;
}

.btn-transparent-sm {
   @apply hover:bg-primary-light inline-flex items-center justify-center px-4 py-2.5 text-title tracking-wide text-primary transition duration-200 rounded whitespace-nowrap leading-4;
}

.btn-danger-transparent-sm {
   @apply hover:bg-red-100 inline-flex items-center justify-center px-4 py-2.5 text-title tracking-wide text-red-600 transition duration-200 rounded whitespace-nowrap leading-4;
}

.btn-success-transparent-sm {
   @apply hover:bg-green-100 inline-flex items-center justify-center px-4 py-2.5 text-title tracking-wide text-green-600 transition duration-200 rounded whitespace-nowrap leading-4;
}

.btn-primary-outline {
   @apply hover:bg-primary-light inline-flex items-center justify-center px-4 py-2 text-title tracking-wide text-primary transition duration-200 rounded bg-white whitespace-nowrap border-primary leading-6;
   border-width: 1px;
}

.btn-danger-sm {
   @apply hover:bg-red-600 inline-flex items-center justify-center px-4 py-2 text-title tracking-wide text-white transition duration-200 bg-red-500 rounded whitespace-nowrap leading-4 border-red-500 disabled:opacity-50 disabled:cursor-not-allowed;
   border-width: 1px;
}

.btn-dropdown-item {
   @apply whitespace-nowrap border-b cursor-pointer px-3 py-3 hover:bg-primary-light;
}